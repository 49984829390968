import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Button } from "@mui/material";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { API_URL } from "../utils";
import MyNFTItem from "../components/MyNFTItem/MyNFTItem";
import { useSelector } from "react-redux";

const MyNFTs = () => {
  const [nfts, setNfts] = useState([]);
  const [time, setTime] = useState([]);
  const { user_id } = useSelector((state) => state.auth);
  const [query, setQuery] = useState("All");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [_refresh, setRefresh] = useState(false);
  const refresh = () => {
    if (user_id) {
      axios
        .get(`${API_URL}/nfts/${user_id}/my-nfts/`, {})
        .then((res) => {
          // Flatten the response data
          const allNFTs = res.data;
          setNfts(allNFTs?.sort((a, b) => -a.id + b.id));
          setTime(new Date().toTimeString());
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  useEffect(() => {
    refresh();
  }, [user_id, _refresh]);

  return (
    <div className="py-2 page bg my-nfts">
      <div className="container px-lg-0 px-md-0 px-sm-0">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            className="mt-3 mb-4"
            style={{
              textAlign: "left",
              width: "auto",
              backgroundColor: "#A0522D",
            }}
            caret
          >
            {query ? query : "All"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem value="" onClick={() => setQuery("All")}>
              {" "}
              All
            </DropdownItem>
            <DropdownItem value="" onClick={() => setQuery("Issuance")}>
              {" "}
              NFTs not on Sale (Issuance)
            </DropdownItem>
            <DropdownItem value="" onClick={() => setQuery("New")}>
              {" "}
              New NFTs
            </DropdownItem>
            <DropdownItem value="" onClick={() => setQuery("Primary Market")}>
              {" "}
              NFTs in the Primary Market
            </DropdownItem>
            <DropdownItem value="" onClick={() => setQuery("Secondary Market")}>
              {" "}
              NFTs in the Secondary Market
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <div className="row mb-3">
          <Grid container spacing={3}>
            {nfts.length === 0 && (
              <p className="text-danger ml-2">
                There are no NFTs to show in your account.
              </p>
            )}
          </Grid>
        </div>

        <div className="mb-4">
          <div className="row">
            {nfts &&
              query === "All" &&
              nfts?.map((n) => {
                return n?.details?.map((res, id) => {
                  return (
                    <div
                      key={n.id + id?.toString() + time}
                      className="col-lg-4 col-md-6 col-xs-12 mb-4"
                    >
                      <MyNFTItem
                        key={n.id + id?.toString() + time}
                        nft={{
                          ...n,
                          available_units: res?.amount,
                          total_supply_units: res?.total,
                          nft_id: res?.id,
                          detail: res,
                          state: res?.state,
                        }}
                        refresh={refresh}
                        nfts={nfts}
                        query={query}
                      />
                    </div>
                  );
                });
              })}
          </div>
          <div className="row">
            {query !== "All" &&
              nfts?.map((n, id) => {
                return n?.details?.map((res) => {
                  if (res?.state === query) {
                    return (
                      <div
                        key={n.id + id?.toString() + time}
                        className="col-lg-4 col-md-6 col-xs-12 mb-4"
                      >
                        <MyNFTItem
                          key={n.id + id?.toString() + time}
                          nft={{
                            ...n,
                            available_units: res?.amount,
                            total_supply_units: res?.total,
                            nft_id: res?.id,
                            detail: res,
                            state: res?.state,
                          }}
                          nfts={nfts}
                          query={query}
                        />
                      </div>
                    );
                  }
                  return <></>;
                });
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNFTs;
