import { useEffect } from 'react';

const SessionManager = () => {
  useEffect(() => {
    const checkSessionExpiry = () => {
      const sessionExpirationTime = localStorage.getItem('sessionExpirationTime');
      if (Date.now() > sessionExpirationTime) {
        // Session expired, log out the user, clear localStorage
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        localStorage.removeItem("access");
        localStorage.removeItem("user_id");
        localStorage.removeItem("history");
        localStorage.removeItem("org_id");
        localStorage.removeItem("wallet-address");
        localStorage.removeItem("sessionExpirationTime");
        localStorage.clear();

        window.location.href = '/login';  // Redirect to login
      }
    };

    // Check session expiry every minute
    const interval = setInterval(checkSessionExpiry, 300000); // 5 minute
    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return null;  // No UI, just background session management
};

export default SessionManager;
