let PRODUCTION = "production";
let LOCAL = "dev";

let MODE = LOCAL;

let API_URL = "",
  APP_URL = "";

const APP_URL_LOCAL = 'https://demonash.com'
// const APP_URL_PRODUCTION = 'https://dcarbonx.onrender.com'

// const LOCAL_URL = "http://127.0.0.1:8000";
const LOCAL_URL = "https://api.demonash.com";
// const LOCAL_URL = "https://backend.dcarbonxdemo.com:8000";
const PRODUCTION_URL = "https://api.demonash.com";


// const APP_URL_LOCAL = "http://localhost:3000/";
// const APP_URL_LOCAL = "https://jellyfish-app-36em8.ondigitalocean.app";
const APP_URL_PRODUCTION = "https://demonash.com"

if (MODE === PRODUCTION) {
  API_URL = PRODUCTION_URL;
  APP_URL = APP_URL_PRODUCTION;
} else {
  API_URL = LOCAL_URL;
  APP_URL = APP_URL_LOCAL;
}

export { APP_URL, API_URL };

export const CONFIG = {
  headers: { "Content-Type": "application/json" },
};
