import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAtom } from "jotai";
import { Grid } from "@mui/material";
import { walletAtom } from "../utils/wallet";
import { API_URL, CONFIG } from "../utils";
import SecondaryItem from "../components/SecondaryItem/SecondaryItem";
import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import { useWallet } from "../utils/wallet-provider";

const SecondaryMarket = () => {
  const navigate = useNavigate();
  const {wallet, setWallet}= useWallet();;
  const [nfts, setNfts] = useState([]);
  const { user_id } = useSelector((state) => state.auth);
  const [loader, set_loader] = useState(false);

  const get_nft = async () => {
    if (user_id){
       set_loader(true);
       axios
          .get(`${API_URL}/nfts/secondary-market/`)
          .then((res) => {
              setNfts(res.data);
              console.log(res.data);
              set_loader(false);
        // console.log("nfts:", res.data);
      })
          .catch((error) => {
              set_loader(false);
              console.log(error.message);
      });
    }
  };

  useEffect(() => {
    get_nft();
  }, [user_id]);

  useEffect(() => {}, []);
  return (
    <div className="py-5 page bg">
      <div className="container px-3 px-lg-0 px-md-0 px-sm-0">
        {loader ? (
          <Loading />
        ) : (
          <Grid container spacing={4}>
            {nfts &&
              nfts.map((nft) => {
                return nft?.details?.map((res) => {
                  return (
                    <SecondaryItem
                      key={res.id}
                      nft={{
                        ...nft,
                        state: res?.state,
                        detail: res,
                        nft_id: res?.id,
                        org_name: res?.organisation_id ?? nft?.org_name,
                      }}
                      get_nft={get_nft}
                    />
                  );
                });
              })}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default SecondaryMarket;
