import React, { useState } from 'react'
import axios from 'axios'
import swal from 'sweetalert';
import { useNavigate, Link } from 'react-router-dom'

import { useForm } from "react-hook-form";
import { Grid, Typography, Box, TextField, Button, CircularProgress } from '@mui/material'
import { API_URL, CONFIG } from '../../utils';


const RequestToRegister = () => {

    const [errorMsg, setErrorMsg] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)
    const [checkErrorMsg, setCheckErrorMessage] = useState(null)

    let navigate = useNavigate();
    const { register, control, errors, formState, handleSubmit } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
            checked: false,
        },
    })

    const onSubmit = data => {
        setShowSpinner(true)

        const object = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            message: data.message,
        }

        const body = JSON.stringify(object);

        axios.post(`${API_URL}/accounts/new/`, body, CONFIG)
            .then(res => {
                if (res.status === 201) {
                    swal({
                        title: "Success",
                        text: "User Registration Success!",
                        icon: "success",
                        button: false,
                        timer: 900,
                    });
                    navigate('/login')
                } else {
                    setErrorMsg(res.data.message)
                }
                setShowSpinner(false)
            })
            .catch((error) => {
                setShowSpinner(false)
                console.log(error.message);
            })
    }

    const onError = (error) => console.log(error)

    const checkboxChange = e => {
        if (e.target.checked === false) {
            setCheckErrorMessage("You need to agree to our data usage terms.")
        }
        else {
            setCheckErrorMessage(false)
        }
    }


    return (
        <div className="py-4 py-md-5 bg page">
            <div className='container px-4 px-lg-0 px-md-0 px-sm-0' >
                <Box maxWidth='xl'>
                    <div className='bg-white  border p-4'>
                        <Typography variant='h4' align='center' sx={{ mb: 3, mt: 4 }}>
                            Register
                        </Typography>

                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <Grid container spacing={2}>
                                <Grid item xs={0} md={2}></Grid>
                                <Grid item xs={12} md={8}>

                                    <Box sx={{ maxWidth: '100%', mb: 2 }} >

                                        <TextField
                                            fullWidth
                                            required
                                            name="name"
                                            label="Name"
                                            variant="outlined"
                                            control={control}
                                            inputRef={register({
                                                required: "Name is required.",
                                            })}
                                            error={Boolean(errors.name)}
                                            helperText={errors.name?.message ? errors.name?.message : "Enter your name"}
                                        />
                                    </Box>

                                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                        <TextField
                                            fullWidth
                                            name='email'
                                            label="Contact Email"
                                            required
                                            control={control}
                                            onFocus={() => setErrorMsg('')}
                                            inputRef={register({
                                                required: "Email is required.",
                                                minLength: {
                                                    value: 6,
                                                    message: "Must have at least 6 characters"
                                                },
                                                pattern: {
                                                    value: /^\S+@\S+$/,
                                                    message:
                                                        "Invalid Email address"
                                                }
                                            })}
                                            error={Boolean(errors.email)}
                                            helperText={errors.email?.message ? errors.email?.message : "Your contact email"}

                                        />
                                    </Box>

                                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                        <TextField
                                            fullWidth
                                            name='phone'
                                            label="Contact Number"
                                            control={control}
                                            inputRef={register()}
                                            error={Boolean(errors.phone)}
                                            helperText={errors.phone?.message ? errors.phone?.message : "Your contact number (please include country code)"}
                                        />
                                    </Box>

                                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                        <TextField
                                            fullWidth
                                            name='message'
                                            label="Message"
                                            control={control}
                                            inputRef={register()}
                                            helperText={"Your message"}
                                        />
                                    </Box>

                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" name="checked" onChange={checkboxChange} />
                                        <span className='ml-2' style={{ marginLeft: "10px", marginBottom: "15px", fontSize: '14px', color: '#00000099' }}>I allow DCarbonX - Nash fintechX to store my data and use it for
                                            purposes described in the <Link to="/privacy-statement" style={{ fontWeight: '400' }} className='text-primary'>Privacy Statement</Link>, among which is to provide the services offered by the platform.
                                            I am aware that I can revoke this right at any instant by a written email to Nash fintechX at <span className='text-primary'>contact@nashfintechx.com</span></span>
                                    </label>
                                    {
                                        checkErrorMsg && <Typography variant='p' sx={{ color: 'red', fontSize: 14, my: 1, display: 'block' }}>{checkErrorMsg}</Typography>
                                    }
                                    {
                                        errorMsg && <Typography variant='p' sx={{ color: 'red', fontSize: 14, my: 1, display: 'block' }}>{errorMsg}</Typography>
                                    }
                                    {/* {
                                        showSpinner &&
                                        <Box sx={{ display: 'flex', mb: 2 }}>
                                            <CircularProgress />
                                        </Box>
                                    } */}

                                    <Box sx={{ maxWidth: '100%', mb: 2, mt: 2 }} >
                                        <Button
                                            color='dark'
                                            variant="contained"
                                            type='submit'
                                            sx={{ color: 'white' }}
                                            disabled={Boolean(Object.keys(formState.errors).length || checkErrorMsg?.length || checkErrorMsg === null || showSpinner)}
                                        >
                                            {showSpinner ? 'Loading...' : 'Submit'}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        {/* </FormProvider> */}
                    </div>

                </Box>
            </div>
        </div>

    )
}

export default RequestToRegister
