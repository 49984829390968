import { z } from "zod";
import { loadStdlib } from "@reach-sh/stdlib";
import { networkConfig } from "../../config/network";
import MakePeraConnect from "../../utils/helper";
import { PeraWalletConnect } from "@perawallet/connect";

export const LocalStorageItems = z.object({
  "PeraWallet.Wallet": z.object({
    type: z.string().nonempty().or(z.literal("pera-wallet")),
    accounts: z.array(z.string().nonempty().min(56)),
    selectedAccount: z.string().nonempty().min(56),
  }),
  walletconnect: z.object({
    connected: z.boolean(),
    accounts: z.array(z.string().nonempty().min(56)),
    chainId: z.number(),

    bridge: z.string().url(),
    key: z.string(),
    clientId: z.string().nonempty(),
    clientMeta: z.object({
      description: z.string(),
      url: z.string().url(),
      icons: z.array(z.any()),
      name: z.string().optional(),
    }),
    peerId: z.string(),
    peerMeta: z.object({
      description: z.string(),
      url: z.string(),
      name: z.string(),
      icons: z.array(z.string()),
    }),
    handshakeId: z.number(),
    handshakeTopic: z.string(),
  }),
});
export type walletTypes = "algo" | "pera" | "WC" | "AS" | "DF" | "default";
export type LocalStorageItemsType = z.infer<typeof LocalStorageItems>;
export type LocalStorageItem = keyof LocalStorageItemsType;
export let reach = loadStdlib({ REACH_NO_WARN: "Y" });

export function moveItemToFirstIndex<T>(item: T, arr: T[]): T[] {
  if (!arr.includes(item)) return arr;
  const newArr = arr.filter((i) => i !== item);
  return [item, ...newArr];
}

export const switchAccount = async (acct: string) => {
  z.string().nonempty().min(56).parse(acct);
  const walletType = window.localStorage.getItem("walletType");
  const TYPES = walletType as walletTypes;
  if (!(TYPES === "pera" || TYPES === "DF")) return;
  const Peraconnect_Wallet = window.localStorage.getItem(
    "PeraWallet.Wallet" satisfies LocalStorageItem
  );
  const Peraconnect_Wallet_Obj = JSON.parse(Peraconnect_Wallet!);
  const walletConnect = window.localStorage.getItem(
    "walletconnect" satisfies LocalStorageItem
  );
  const walletConnect_Obj = JSON.parse(walletConnect!);
  let item = LocalStorageItems.parse({
    "PeraWallet.Wallet": Peraconnect_Wallet_Obj,
    walletconnect: walletConnect_Obj,
  });
  //?We are setting the variable for the perawallet instance on localstorage to enable us
  //? Change wallets on pera and Defly wallets
  item["PeraWallet.Wallet"].accounts = moveItemToFirstIndex(
    acct,
    item["PeraWallet.Wallet"].accounts
  );
  item["PeraWallet.Wallet"].selectedAccount = acct;

  //?We are setting the variable for the walletConnect instance on localstorage to enable us
  //? Change wallets on pera and Defly wallets
  item["walletconnect"].accounts = moveItemToFirstIndex(
    acct,
    item["walletconnect"].accounts
  );

  // * We start storing on local storage
  window?.localStorage.setItem(
    "PeraWallet.Wallet",
    JSON.stringify(item["PeraWallet.Wallet"])
  );
  window?.localStorage.setItem(
    "walletconnect",
    JSON.stringify(item["walletconnect"])
  );
  await window?.algorand.wc.ensurePC();
  const addr = await window?.algorand.wc.getAddr();
  const _acct = await reach.connectAccount({ addr });
  return _acct;
};

export const connectWallet = async (wallet = "pera") => {
  if (wallet == "pera") {
    delete window?.algorand;
    reach = loadStdlib({ REACH_NO_WARN: "Y" });
    reach.setWalletFallback(
      reach.walletFallback({
        providerEnv: networkConfig?.network,
        WalletConnect: MakePeraConnect(PeraWalletConnect),
      })
    );
    const account = await reach.getDefaultAccount();
    // Blocks
    let accounts: string[] | undefined;
    // if (wallet === "pera") {
    //   const Peraconnect_Wallet = window.localStorage.getItem(
    //     "PeraWallet.Wallet" satisfies LocalStorageItem
    //   );
    //   const Peraconnect_Wallet_Obj = JSON.parse(Peraconnect_Wallet!);
    //   const walletConnect = window.localStorage.getItem(
    //     "walletconnect" satisfies LocalStorageItem
    //   );
    //   const walletConnect_Obj = JSON.parse(walletConnect!);
    //   let item = LocalStorageItems.parse({
    //     "PeraWallet.Wallet": Peraconnect_Wallet_Obj,
    //     walletconnect: walletConnect_Obj,
    //   });
    //   accounts = item["PeraWallet.Wallet"].accounts;
    // }
    //?We are setting the variable for the perawallet instance on localstorage to enable us
    //? Change wallets on pera and Defly wallets
    // Blocks
    // setWallet(account);
    // setBalanceMsg("");
    // setAccounts([
    //   {
    //     address: account.networkAccount.addr,
    //     name: account.networkAccount.addr,
    //   },
    // ]);
    return {
      wallet: account,
      balanceMessage: "",
      accounts: accounts?.map((res) => ({ address: res, name: res })) ?? [
        {
          address: account.networkAccount.addr,
          name: account.networkAccount.addr,
        },
      ],
      params:undefined

    };
  } else {
    delete window?.algorand;
    const account = await reach.getDefaultAccount();
     return {
      wallet: undefined,
      balanceMessage: "",
      accounts: [],
      params:undefined
    };
  }

  // setBalanceMsg("");
  // setAccounts();
};

declare global {
  interface Window {
    algorand: any;
    AlgoSigner: any;
  }
}
