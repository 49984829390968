import React, { useEffect, useState } from "react";
import { NavLink, useNavigate ,useHref} from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Typography, Button, Box } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
// import logo from "../../assets/DCarbonXlogo_brown.png";
import "./navbar.css";
import { API_URL, APP_URL, CONFIG } from "../../utils";
import { IoMdArrowDropdown } from "react-icons/io";

import MyAlgoConnect from "@randlabs/myalgo-connect";
import { accountsAtom, walletAtom } from "../../utils/wallet";
import { useAtom } from "jotai";
import { connectWallet, reach } from "./utils.tsx";
import { useWallet } from "../../utils/wallet-provider";
const algosdk = require("algosdk");

const myAlgoWallet = new MyAlgoConnect();
const Navbar = () => {
  const dispatch = useDispatch();
  const { role, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const href=useHref()
  const [showSetting, setShowSetting] = useState(false);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [accountNo, setAccountNo] = useState("");
  const [refund, setRefund] = useState(false);
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const {wallet, setWallet}= useWallet();;
  const [errorOccured, setErrorOccured] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceMsg, setBalanceMsg] = useState("");
  const [param, setParam] = useState({});
  const algodClient = new algosdk.Algodv2(
    "",
    "https://node.testnet.algoexplorerapi.io/",
    ""
  );
  const [formData, setFormData] = useState({
    address: wallet?.networkAccount?.addr??"",
  });
  useEffect(()=>{
    if(wallet?.networkAccount?.addr){setFormData({
      ...formData,
      address: wallet?.networkAccount?.addr
    })
    setAccounts([{
      address:wallet?.networkAccount?.addr,
      name:wallet?.networkAccount?.addr
    }])  
  }
  },[wallet?.networkAccount?.addr])
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const logout = () => {
    const object = {
      token: token,
    };
    const body = JSON.stringify(object);
    axios
      .post(`${API_URL}/accounts/logout/`, body, CONFIG)
      .then((res) => {
        if (res.status == 200) {
          localStorage.removeItem("email");
          localStorage.removeItem("role");
          localStorage.removeItem("access");
          localStorage.removeItem("user_id");
          localStorage.removeItem("history");
          localStorage.removeItem("org_id");
          localStorage.removeItem("wallet-address");
          localStorage.clear();

          // ! Disconnet wallet
          if (typeof window?.algorand !== "undefined") {
            window.algorand.disconnect();
            delete window?.algorand;
          }
          window.localStorage.removeItem("PeraWallet.Wallet");
          window.localStorage.removeItem("walletconnect");
          setAccounts([]);
          setWallet({});
          setFormData({
            ...formData,
            address:undefined
          })
          // ! Disconnect wallet
          
          swal({
            title: "Success!",
            text: "You are now logged out.",
            icon: "success",
            button: false,
            timer: 1500,
          });
          navigate("/");
          dispatch({ type: "LOGOUT" });
        } else {
          swal({
            title: "Failed!",
            text: `${res.data.message}`,
            icon: "error",
            button: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const conenctStripe = async () => {
    swal({
      title: "",
      text: "Please wait while you are redirected to the payment server.",
      icon: "info",
      button: false,
      timer: 2000,
    });
    await axios
      .get(`${API_URL}/organisations/create-stripe-connect/`, CONFIG)
      .then((res) => {
        // console.log(res.data)
        localStorage.setItem("acc_no", res.data.account_no);
        setAccountNo(res.data.account_no);
        window.location.replace(res.data.account_link.url);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const goHome = () => {
    setRefund(false);
    navigate(`/home`);
  };
  const goRequestRegister = () => {
    setRefund(false);
    navigate(`/register/request`);
  };

  const ConnectWallet = async (wallet = "pera" || "algo") => {
    const connect = await connectWallet(wallet)
    setWallet(connect.wallet)
    setBalanceMsg(connect.balanceMessage)
    setAccounts(connect.accounts)
    setParam(connect?.params)
    setFormData({
      ...formData,
      address:connect.accounts?.at(0).address
    })
    console.log("connection");

    // setBalanceMsg("");
    // setAccounts();
  };

  const setParams = () => {
    algodClient
      .getTransactionParams()
      .do()
      .then((d) => {
        setParam(d);
        console.log("params: ", d);
        // setFormData({
        //   ...formData,
        //   suggestedParams: d,
        // });
        setBalanceMsg("");
      })
      .catch((e) => {
        setErrorOccured(true);
        console.error(e);
      });
  };

  return (
    <nav className="navbar navbar-expand-lg bg-white">
      <div className="container-fluid py-2 px-3">
        <NavLink to="/" className="navbar-brand ml-2">
          <img
            className="pb-1"
            src={`${APP_URL}/DCarbonXlogo_brown.png`}
            alt="DcarbonX"
            width="120px"
            loading="lazy"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setIsMenuClicked(!isMenuClicked)}
        >
          <span className="navbar-toggler-icon">
            {isMenuClicked === true ? (
              <CloseIcon sx={{ pb: 0, fontSize: 24 }} />
            ) : (
              <MenuIcon sx={{ pb: 0, fontSize: 24 }} />
            )}
          </span>
        </button>

        <div className="collapse navbar-collapse ml-4 mr-2" id="main_nav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to={"/home"} className="nav-link">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <div className="d-flex justify-content-start align-items-center">
                <a className="nav-link">Register</a>
                <span>
                  <IoMdArrowDropdown />
                </span>
              </div>
              <ul className="parent_menu">
                <li className="li">
                  <NavLink className="nav-link" to={"/register/request"}>
                    Request to Register
                  </NavLink>
                </li>
                <li className="li">
                  <NavLink className="nav-link" to={"/register/form"}>
                    Registration Form{" "}
                  </NavLink>
                </li>
                {/* <li className='parent_menu_item li'>
                                    <a className="nav-link" style={{ cursor: 'pointer' }}> Registration Form &raquo; </a>
                                    <ul className="sub_menu">
                                        <li className='li'><NavLink className="nav-link" to={'/register/form'}>Basic details</NavLink></li>
                                        {/* { role === "Organisation" ?
                                            <li className='li'><a className="nav-link" style={{ cursor: 'pointer' }} onClick={conenctStripe}>Payment details</a></li>
                                            : 
                                            <li className='li'><a className="nav-link" style={{ cursor: 'default' }} onClick={() => setRefund(true)}>Payment details</a></li>
                                        } 
                                    </ul>
                                </li> */}
              </ul>
            </li>
            {role && <li className="nav-item">
              <NavLink className="nav-link" to={"/primary-market"}>
                Primary market
              </NavLink>
            </li>}
            {role && <li className="nav-item">
              <NavLink className="nav-link" to={"/secondary-market"}>
                Secondary market
              </NavLink>
            </li>}
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href={`https://next.cnet0.com/offset-your-emissions`}
              >
                Offset Your Emissions
              </a>
            </li>
            {role && <li className="nav-item">
              <NavLink className="nav-link" to={"/registered-organisations"}>
                Organisations
              </NavLink>
            </li>}
            {(localStorage.getItem("role") === "Approved" ||
              localStorage.getItem("role") === "W-Approved") && (
              <li className="nav-item">
                <NavLink className="nav-link" to={"/my-nfts"}>
                  My NFTs
                </NavLink>
              </li>
            )}
            {localStorage.getItem("role") === "W-Approved" && (
              <li className="nav-item">
                <NavLink className="nav-link" to={"/create-nfts"}>
                  Create NFT
                </NavLink>
              </li>
            )}
            {localStorage.getItem("role") === "W-Approved" && !href?.includes("/create-nfts") && (
              <li className="nav-item">
                {accounts.length ? (
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle
                      className="w-100"
                      caret
                      style={{ backgroundColor: "#7B3F00" }}
                    >
                      {formData.address
                        ? formData.address.slice(0, 8) + "..."
                        : "Confirm Wallet"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {accounts.map((account, id) => (
                        <DropdownItem
                          key={id.toString()}
                          value={account.address}
                          onClick={(e) => {
                            setFormData({
                              ...formData,
                              address: e.currentTarget.textContent,
                            });
                            localStorage.setItem(
                              "wallet-address",
                              e.currentTarget.textContent
                            );
                          }}
                        >
                          {account.address}
                        </DropdownItem>
                      ))}
                      <DropdownItem>
                        <Button
                        style={{
                          width: "100%",
                        }}
                          onClick={() => {
                           
                            if (typeof window?.algorand !== "undefined") {
                              window.algorand.disconnect();
                              delete window?.algorand;
                            }
                            window.localStorage.removeItem("PeraWallet.Wallet");
                            window.localStorage.removeItem("walletconnect");
                            setAccounts([]);
                            // ConnectWallet(false);
                            setWallet({});
                            setFormData({
                              ...formData,
                              address:undefined
                            })
                          }}
                        >
                          Disconnect wallet
                        </Button>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <div className="d-flex connect">
                    <Box className="w-100 connectBtn" style={{ margin: "0" }}>
                      <ConnectWalletButton
                        style={{ padding: "5px" }}
                        connectWallet={ConnectWallet}
                      />
                    </Box>
                  </div>
                )}
              </li>
            )}
          </ul>
          <ul className="navbar-nav ms-auto d-flex right-nav flex-row  justify-content-start align-items-start align-items-lg-center ">
            <li className="nav-item li">
              <a
                href="https://www.linkedin.com/company/nash-sarl-s"
                target="_blank"
                className={`social-link nav-link`}
                style={{ padding: 0 }}
              >
                <LinkedInIcon color="#7b3f00" />
              </a>
            </li>

            <li className="nav-item li">
              <a
                href="https://twitter.com/NashfintechX"
                target="_blank"
                className={`social-link nav-link`}
                style={{ padding: 0 }}
              >
                <TwitterIcon color="#7b3f00" />
              </a>
            </li>
            <li className="nav-item auth li">
              <a className="nav-link">
                <div className="d-flex justify-content-start align-items-center">
                  <AccountCircleIcon sx={{ color: "#7b3f00" }} />
                  <span style={{ fontSize: "17px", color: "#7b3f00" }}>
                    <IoMdArrowDropdown />
                  </span>
                </div>
              </a>
              <ul className="parent_menu login">
                {role ? (
                  <div>
                    <li className="nav-item li">
                      <NavLink to={"profile"} className="nav-link">
                        Profile
                      </NavLink>
                    </li>
                    <li className="nav-item li">
                      <NavLink to={"dashboard"} className="nav-link">
                        Dashboard
                      </NavLink>
                    </li>
                    <li className="nav-item log li">
                      <NavLink to={"change-password"} className="nav-link">
                        Change Password
                      </NavLink>
                    </li>
                    <li className="nav-item log li">
                      <NavLink to={"action"} className="nav-link">
                        Climate Action
                      </NavLink>
                    </li>
                    <li className="nav-item li" onClick={logout}>
                      <NavLink to={""} className="nav-link">
                        Logout
                      </NavLink>
                    </li>
                  </div>
                ) : (
                  <div>
                    <li className="nav-item log li">
                      <NavLink to={"login"} className="nav-link">
                        Login
                      </NavLink>
                    </li>

                    <li className="nav-item log li">
                      <NavLink to={"register/request"} className="nav-link">
                        Register
                      </NavLink>
                    </li>
                  </div>
                )}
              </ul>
            </li>
          </ul>
        </div>
        {/* <!-- navbar-collapse.// --> */}
      </div>
      {/* <!-- container-fluid.// --> */}

      <div className={`refund-model ${refund ? "show" : "hide"}`}>
        <div className="main-con">
          <div className="about-header">
            <h6 className="my-0 text-center py-1">Payment details</h6>
            <div onClick={() => setRefund(false)} className="close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
          <div className="refund-body">
            <div className="d-flex justify-content-center align-items-center mb-1 mt-2 py-3">
              <p>
                You need to be a registered organisation to access this feature
              </p>
            </div>
          </div>
          <div className="refund-bottom px-3">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <Button
                size="small"
                variant="contained"
                onClick={goHome}
                sx={{
                  width: "100%",
                  background: "#76C4C780",
                  "&:hover": {
                    color: "white",
                  },
                }}
              >
                <Typography
                  variant="button"
                  color="black"
                  sx={{
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  Close
                </Typography>
              </Button>

              <Button
                size="small"
                variant="contained"
                onClick={goRequestRegister}
                sx={{ width: "100%", background: "#008F0052" }}
              >
                <Typography
                  fontSize={16}
                  variant="button"
                  color="black"
                  sx={{
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  Register
                </Typography>
              </Button>
            </div>
          </div>
          {/* // onClick={navigate(`/register/request`)}  */}
        </div>
      </div>
    </nav>
  );
};
const ConnectWalletButton = ({ connectWallet }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="relative "
      style={{ backgroundColor: "#7b3f00", borderRadius: "5px" }}
    >
      <button
        className="btn w-100 p-2 nav-link"
        type="button"
        onClick={() => {
          setOpen(!open);
        }}
        style={{ fontSize: "15px", fontWeight: 550, color: "white" }}
      >
        Connect Wallet
      </button>
      <div
        style={{
          display: open ? "flex" : "none",
          flexDirection: "column",
          gap: "0.1rem",
          position: "absolute",
          top: "100%",
          width: "100%",
          backgroundColor: "#ededed",
        }}
        className={`absolute bottom-0 flex-col gap-0`}
      >
        <button
          type="button"
          className="btn w-100 p-2 nav-link"
          onClick={() => {
            connectWallet("pera");
            setOpen((prev) => !prev);
          }}
          style={{
            fontSize: "15px",
            zIndex: "1000",
            color: "black",
            backgroundColor: "#ededed",
          }}
        >
          Pera
        </button>
        {/* <button
                    type="button"
                    className="btn w-100 p-2 nav-link"
                    onClick={() => {
                        connectWallet("algo");
                        setOpen((prev => !prev));
                    }}
                    style={{ fontSize: '15px', zIndex: '1000', color: 'black', backgroundColor: '#ededed', }}
                >
                    AlgoSigner
                </button> */}
      </div>
    </div>
  );
};

export default Navbar;
