import React from 'react'
import { Label, FormGroup, Input } from 'reactstrap';

const FormPartOne = ({ formData, setFormData, fileErrorMessage, handleFileUpload, isFileLoaded }) => {

    return (
        <div>
            <FormGroup>
                <Label for="fileId">
                    File Upload
                </Label>
                <Input
                    id="fileId"
                    name="file"
                    type="file"
                    onChange={(event) => handleFileUpload(event)}
                />
                {/* accept="image/jpeg,image/png,image/gif,application/pdf" */}
                {fileErrorMessage && <p style={{ color: '#af3f3b' }}>{fileErrorMessage}</p>}
            </FormGroup>

        </div>
    )
}

export default FormPartOne
