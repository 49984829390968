import React, { useState } from 'react';
import { Label, FormGroup, Input } from 'reactstrap';
import { produce } from "immer";


const FormPartTwo = ({ attribute, setAttribute, formData, setFormData, nftDescriptionErrorMessage, ownerEmailErrorMessage, handleOwnerEmailChange, handleNFTDescriptionChange }) => {

    const [attrIndex, setAttrIndex] = useState(0)

    const handleAttribute = () => {
        setFormData({ ...formData, attribute: [...attribute] });
        setAttrIndex(attrIndex + 1);
        setAttribute([...attribute, {
            name: "",
            value: "",
            unit: "",
        }])
    }

    return (
        <div>
            {/* <FormGroup>
                <Label for="titleId">
                    NFT Owner
                </Label>
                <Input
                    id="ownerId"
                    name="certificateOwner"
                    type="text"
                    value={formData.certificateOwner}
                    onChange={(e) => {
                        setFormData({ ...formData, certificateOwner: e.target.value});
                    }}
                />
            </FormGroup> */}

            <FormGroup>
                <Label for="ownerId">
                    NFT Owner's Email
                </Label>
                <Input

                    id="ownerId"
                    name="certificateOwnerEmail"
                    type="email"
                    aria-label='email'
                    value={formData.certificateOwnerEmail}
                    onChange={
                        handleOwnerEmailChange
                    }
                />
                {ownerEmailErrorMessage && <p style={{ color: '#af3f3b' }}>{ownerEmailErrorMessage}</p>}
            </FormGroup>

            <FormGroup>
                <Label for="descriptionId">
                    NFT Description
                </Label>
                <Input
                    id="descriptionId"
                    name="description"
                    type="textarea"
                    value={formData.description}
                    onChange={(e) => {
                        handleNFTDescriptionChange(e);
                    }}
                />
                {nftDescriptionErrorMessage && <p style={{ color: '#af3f3b' }}>{nftDescriptionErrorMessage}</p>}
            </FormGroup>

            {/* <FormGroup>
                <Label for="maximumSupplyId">
                    Maximum Supply
                </Label>
                <Input
                    id="maximumSupplyId"
                    name="maximumSupply"
                    type="number"
                    min={1}
                    step={1}
                    value={formData.maximumSupply}
                    onChange={(e) => {
                        setFormData({ ...formData, maximumSupply: e.target.value, total: e.target.value });
                    }}
                />
            </FormGroup> */}

            {/* {
                attribute.map((att, index) => ( 
                    <Row key={index}>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="nameId">
                                Name
                            </Label>
                            <Input
                                id="nameId"
                                name="name"
                                type="text"
                                value={att.name}
                                onChange={(e) => {
                                    const name = e.target.value;
                                    setAttribute(currentAttribute =>
                                        produce(currentAttribute, v => {
                                            v[index].name = name;
                                        })
                                    );
                                }}
                            />
                        </FormGroup>
                        </Col>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="valueId">
                                Value
                            </Label>
                            <Input
                                id="valueId"
                                name="state"
                                type="text"
                                value={att.value}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setAttribute(currentAttribute =>
                                        produce(currentAttribute, v => {
                                            v[index].value = value;
                                        })
                                    );                                   
                                }}
                            />
                        </FormGroup>
                        </Col>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="unitId">
                                Unit
                            </Label>
                            <Input
                                id="unitId"
                                name="unit"
                                type="text"
                                value={att.unit}
                                onChange={(e) => {
                                    const unit = e.target.value;
                                    setAttribute(currentAttribute =>
                                        produce(currentAttribute, v => {
                                            v[index].unit = unit;
                                        })
                                    );
                                }}
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    ) 
                )
            } */}
            
            {/* <Button color='info' outline onClick={handleAttribute}>Add an Attribute</Button> */}
        </div>
    )
}

export default FormPartTwo
