import React, { useContext, useState } from "react";
import { wallet } from "./functions/arc69";

const AppContext = React.createContext(
  {} as {
    wallet?: wallet;
    setWallet: React.Dispatch<React.SetStateAction<wallet>>;
  }
);

export const useWallet = () => useContext(AppContext);

export default function WalletProvider({ children }: { children: React.ReactNode }) {
  const [wallet, setWallet] = useState({} as wallet);
  return (
    <AppContext.Provider value={{ wallet, setWallet }}>
      {children}
    </AppContext.Provider>
  );
}
