import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme, Typography } from "@mui/material";
import axios from "axios";
import { tokens } from "../theme";
import { useSelector } from "react-redux";
import { API_URL } from "../utils";

const BarChart = ({ isDashboard = false }) => {
  const [barData, setBarData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { org_id } = useSelector((state) => state.auth);
  
  // New state to track maximum value for dynamic scaling
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    fetchData();
  }, [org_id]);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/organisations/dashboard/data/${org_id}/`
      );
      const formattedData = formatBarData(res.data);
      setBarData(formattedData);
      setMaxValue(getMaxPrice(formattedData)); // Update max value
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  // Function to calculate the maximum price value for scaling
  const getMaxPrice = (data) => {
    return Math.max(...data.map((item) => item.price));
  };

  const formatBarData = (data) => {
    const { nft_sell_history_data } = data;

    const sellData = nft_sell_history_data.map((item) => ({
      id: item.id,
      state: "Sell",
      price: item.price,
    }));

    return sellData; // Only return sell data
  };

  const formatNumber = (value) => {
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + "B";
    }
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M"; // Format for millions
    } else if (value >= 100000) {
      return (value / 1000).toFixed(1) + "K"; // Format for thousands
    } else if (value >= 10000) {
      return (value / 1000).toFixed(1) + "K";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    }
    return value;
  };

  return (
    <>
      {barData && barData.length > 0 ? (
        <ResponsiveBar
          data={barData}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.gray[100],
                },
              },
              legend: {
                text: {
                  fill: colors.gray[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.gray[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.gray[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.gray[100],
              },
            },
          }}
          keys={["price"]}
          indexBy="id"
          margin={{ top: 50, right: 130, bottom: 50, left: 75 }}
          padding={0.3}
          valueScale={{ type: "linear", max: maxValue * 1.1 }} // Auto-scale based on max value
          indexScale={{ type: "band", round: true }}
          colors="#8B4513"
          borderColor={{
            from: "color",
            modifiers: [["darker", "1.6"]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Carbon Credits", // X-axis label
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Price", // Y-axis label
            legendPosition: "middle",
            legendOffset: -60,
            tickValues: [0, maxValue * 0.25, maxValue * 0.5, maxValue * 0.75, maxValue], // Dynamic tick values
            format: formatNumber, // Use custom formatter for both K and M
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          barAriaLabel={function (e) {
            return e.id + ": " + e.formattedValue + " in state: " + e.indexValue;
          }}
        />
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Typography>No Data Found</Typography>
        </div>
      )}
    </>
  );
};

export default BarChart;