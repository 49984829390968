import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_URL } from "../utils";

const LineChart = ({ setRevenueData, isDashboard = false }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [chartData, setChartData] = useState([]);
  const { org_id, org_name } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log("ORG ID IS ", org_id);
    fetchData();
  }, [org_id]);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/organisations/dashboard/data/${org_id}/nft-line-data/`
      );

      const primaryMarketData = res.data
        .filter((item) => item.market === "Primary Market")
        .map((item) => ({ x: item.carbon_credits, y: item.price }));

      const secondaryMarketData = res.data
        .filter((item) => item.market === "Secondary Market")
        .map((item) => ({ x: item.carbon_credits, y: item.price }));

      const formattedData = [
        {
          id: "Primary",
          color: "hsl(220, 70%, 50%)",
          data: primaryMarketData,
        },
        {
          id: "Secondary",
          color: "hsl(100, 70%, 50%)",
          data: secondaryMarketData,
        },
      ];

      setChartData(formattedData);
      setRevenueData(formattedData); // Pass the data to Action component
    } catch (error) {
      console.error("Error fetching chart data", error);
    }
  };
  console.log(chartData)

  return (
    <>
      {
        (chartData && chartData.length > 0 && chartData[0].data && chartData[0].data.length > 0) || (chartData && chartData.length > 0 && chartData[1].data && chartData[1].data.length > 0) ? <ResponsiveLine
          data={chartData}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.gray[100],
                },
              },
              legend: {
                text: {
                  fill: colors.gray[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.gray[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.gray[100],
                  fontSize: 12,
                },
              },
            },
            legends: {
              text: {
                fill: colors.gray[100],
              },
            },
            tooltip: {
              container: {
                color: "#000000",
              },
            },
          }}
          colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
          margin={{ top: 50, right: 110, bottom: 70, left: 80 }}
          xScale={{ type: "linear", min: 0, max: "auto" }}
          yScale={{
            type: "linear",
            min: 0,
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="linear"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: "Carbon Credits",
            legendOffset: 50,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (value) => {
              if (value >= 1000000) {
                return `${(value / 1000000).toFixed(1)}M`;
              } else if (value >= 1000) {
                return `${(value / 1000).toFixed(1)}K`;
              } else {
                return value;
              }

            },
            legend: "Cost",
            legendOffset: -62,
            legendPosition: "middle",
          }}
          enableGridX={true}
          enableGridY={true}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        /> : <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Typography>
            No Data Found
          </Typography>
        </div>
      }
    </>

  );
};

export default LineChart;