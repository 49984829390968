import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useAtom } from "jotai";
import { Input, Label, FormGroup, Spinner } from "reactstrap";
import { Paper, Typography, Grid, Button } from "@mui/material";
import { BuyPrimary } from "../../utils/functions/interact";
import { accountsAtom, walletAtom } from "../../utils/wallet";
import { API_URL, CONFIG } from "../../utils";
import algo from "../../assets/algo.png";
import "./primary_item.css";
import { contract_views } from "../../utils/functions/contract_views";
import { reach } from "../../utils/functions/arc69";
import { networkConfig } from "../../config/network";
import { loadStdlib } from "@reach-sh/stdlib";
import MakePeraConnect from "../../utils/helper";
import { PeraWalletConnect } from "@perawallet/connect";
import Swal from "sweetalert2";
import { connectWallet } from "../Navbar/utils";
import { useWallet } from "../../utils/wallet-provider";

const PrimaryItem = ({ nft, get_nft }) => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const {wallet, setWallet}= useWallet();;
  const [toggleForm, setToggleForm] = useState(false);
  const [buyClicked, setBuyClicked] = useState(false);
  const [formData, setFormData] = useState({
    unit: "",
    total_price: 0,
  });
  const [componentState, setComponentState] = useState(
    nft?.detail?.detail_state
  );
  useEffect(() => {
    if (
      nft.detail.state == "Secondary Market" ||
      nft.detail.state == "Primary Market"
    ) {
      const view = contract_views({
        info: nft.detail.contract_id,
        first: nft.detail.state == "Secondary Market" ? false : true,
      }).then(async (view) => {
        const res = await view.halted();
        if (componentState !== "stopped") {
    
          setComponentState(res?.at(1) == true ? "halted" : "active");
        }
      });
    }
  }, []);

  // const connectWallet = async (wallet = "pera" || "algo") => {
  //   if (wallet == "pera") {
  //     delete window?.algorand;
  //     reach = loadStdlib({ REACH_NO_WARN: "Y" });
  //     reach.setWalletFallback(
  //       reach.walletFallback({
  //         // providerEnv: "MainNet",
  //         providerEnv: networkConfig.network,
  //         WalletConnect: MakePeraConnect(PeraWalletConnect),
  //       })
  //     );
  //   } else {
  //     delete window?.algorand;
  //   }
  //   const account = await reach.getDefaultAccount();
  //   setWallet(account);
  // };

  const [meta, setMeta] = useState();
  const [total, setTotal] = useState();

  // console.log("nft:", nft)
  let grand_id = nft.id;
  if (nft.grand_parent !== null) {
    grand_id = nft.grand_id;
  }

  const getExtension = (filename) => {
    return filename?.split(".").pop();
  };
  const verifyWallet = () => {
    Swal.fire({
      title: "Missing Wallet!",
      text: `Please connect your wallet and try again`,
      icon: "error",
    });

    // throw Error("No wallet connected");
  };
  const buyFromPrimary = (nft) => {
    if (!wallet?.networkAccount?.addr) {
      return verifyWallet();
    }
    setBuyClicked(true);
    if (
      localStorage.getItem("role")?.includes("Approved") &&
      localStorage.getItem("org_id") != "-1"
    ) {
      if (Object.keys(wallet).length === 0) {
        swal({
          title: "Missing Wallet!",
          text: `Please connect your wallet and try again`,
          icon: "error",
          // button: false,
          // timer: 1200,
          buttons:[
            {text:"connect wallet"}
          ]
        });
        setBuyClicked(false);
        return;
      }
      if (
        formData.unit.includes(".") ||
        formData.unit > nft?.detail?.total ||
        formData.unit < 1
      ) {
        return swal({
          title: "Invalid Input!",
          text: `Unit should be a positive integer and not more than the available.`,
          icon: "error",
          button: false,
          timer: 1500,
        });
      }
      const user = localStorage.getItem("user_id");

      const param = {
        wallet: wallet,
        amount: formData.unit,
        tok: nft?.asset_id,
        info: nft?.detail?.contract_id,
      };
      BuyPrimary(param).then((response) => {
        console.log("res:", response);
        if (response.includes("Successfully bought")) {
          const object = {
            user: user,
            state: "Primary Market",
            unit: formData.unit,
            total_price: formData.total_price,
            address: localStorage.getItem("wallet-address")??wallet?.networkAccount?.addr,
            detail_id: nft?.detail?.id,
          };
          const body = JSON.stringify(object);

          axios
            .patch(`${API_URL}/nfts/${nft.id}/buy-in-primary/`, body, CONFIG)
            .then((res) => {
              if (res.status === 200) {
                swal({
                  title: "Success!",
                  text: `Successfully bought  ${formData.unit} tokens`,
                  icon: "success",
                  button: false,
                  timer: 1500,
                });
                get_nft();
              }
            })
            .catch((error) => {
              console.log(error.message);
              swal({
                title: "Failed!",
                text: `Failed to purchase`,
                icon: "error",
                button: false,
                timer: 1500,

              });
            });
        } else {
          swal({
            title: "Failed!",
            text: `Failed to purchase`,
            icon: "error",
            button: false,
            timer: 1500,
          });
        }
        setBuyClicked(false);
      }).catch((error) => {
        if(error?.message?.toString()?.toLowerCase()?.includes("tried to spend")){
          Swal.fire({
            title: "Failed!",
            text: `Insufficient funds in your wallet`,
            icon: "error",
            button: false,
            timer: 1500,
          })
        }
        else if(error?.toString()?.toLowerCase()?.includes("below min")){
          Swal.fire({
            title: "Failed!",
            text: `Your balance is too low for this transaction please get more funds.`,
            icon: "error",
            button: false,
          });
        }
        else   if (
          error?.message
            ?.toString()
            ?.includes("Request Rejected: The User has rejected the request.")
        ) {
          Swal.fire({
            title: "Failed!",
            text: `The User has rejected the request.`,
            icon: "error",
            button: false,
          });
        }
        else   if (
          (
            error?.message
              ?.toString()
              ?.includes("balance is less.")
          )  ) {
          Swal.fire({
            title: "Failed!",
            text: `Balance is less than the requested amount.`,
            icon: "error",
            button: false,
          });
        }
       else{
        Swal.fire({
          title: "Failed!",
          text: `Failed to purchase`,
          icon: "error",
          button: false,
          timer: 1500,
        })
       }
      });
    } else {
      swal({
        title: "Unauthorized User!",
        text: `You should be a registered organisation to use this service.`,
        icon: "error",
        button: false,
        timer: 1500,
      });
      setBuyClicked(false);
      return;
    }
    setBuyClicked(false);
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      {nft.tx_id.length ? (
        <Paper
          className="detail-page-link ml-0"
          sx={{ p: 2 }}
          style={{ boxShadow: "none", border: "1px solid #d9dbdd" }}
        >
          <span onClick={() => navigate(`/market-history/${grand_id}/`)}>
            {getExtension(nft.file).toLowerCase() === "pdf" && (
              <iframe src={nft.asset_url} width="100%" height="220px"></iframe>
            )}
            {getExtension(nft.file).toLowerCase() !== "pdf" && (
              <img
                src={nft.asset_url}
                alt="Thumbnail"
                height={220}
                width="100%"
                className="my-1"
              />
            )}
          </span>
          <div>
            <Typography sx={{ marginTop: "8px" }}>
              DCarbonX ID: <b>{nft?.detail?.id}</b>
            </Typography>
            <Typography sx={{ fontSize: "16px", marginTop: "10px" }}>
              Issuer of Fractionalised NFT: <strong>{nft.org_name}</strong>
            </Typography>
            <Typography sx={{ fontSize: "16px", marginTop: "10px" }}>
              Carbon Credits in NFT:{" "}
              <strong>
                {nft.carbon_credits} {nft.unit_name}
              </strong>
            </Typography>
            <Typography sx={{ fontSize: "16px" }}>
              Total Supply of Units: <strong>{nft?.detail?.total}</strong>
            </Typography>
            {/* {nft?.detail?.total > nft.occupied && ( */}
            <Typography sx={{ fontSize: "16px" }}>
              Available Units: <strong>{nft?.detail?.amount}</strong>
            </Typography>
            {/* )} */}
            <Typography sx={{ fontSize: "16px", marginTop: "0px" }}>
              1 Unit:{" "}
              <strong>
                {nft?.detail?.total ? nft.carbon_credits / nft?.total : ""}{" "}
                {nft?.unit_name}
              </strong>
            </Typography>
            <Typography sx={{ fontSize: "16px", marginTop: "0px" }}>
              Units Sold:{" "}
              <strong>
                {(nft?.detail?.total ?? 0) - (nft?.detail?.amount ?? 0)}
              </strong>
            </Typography>
            <Typography sx={{ fontSize: "16px", marginTop: "5px" }}>
              Price/ Unit:{" "}
              <img className="m-1" src={algo} alt="thumb" width={20} />
              <strong>{nft?.detail?.price / 1000000}</strong>
            </Typography>
            <Typography sx={{ fontSize: "16px", marginTop: "5px" }}>
              Holders of Sold Units:
            </Typography>
            <ul style={{ marginTop: "-10px", marginBottom: "5px" }}>
              {nft.holders.map((holder) => {
                return (
                  <li
                    key={holder}
                    style={{ listStyleType: "none", fontSize: "14px" }}
                  >
                    <strong>{holder}</strong>
                  </li>
                );
              })}
            </ul>

            <Typography
              sx={{ fontSize: "16px", marginTop: "10px" }}
              onClick={() =>
                window.open(
                  `${networkConfig?.explorer?.asset}${nft.asset_id}`,
                  "_blank"
                )
              }
            >
              Details of All Transactions: <br />
              <span style={{ wordBreak: "break-all", color: "#335383FF" }}>
                {networkConfig?.explorer?.asset}
                {nft?.asset_id}
              </span>
            </Typography>
        <div className="d-flex justify-content-between align-items-top mb-3 ">
              {nft.detail?.amount === 0 && componentState !== "stopped" && (
                <Typography className="sold-out">SOLD OUT</Typography>
              )}
              {componentState === "halted" && (
                <Typography className="sold-out">INACTIVE</Typography>
              )}
              {componentState === "stopped" && (
                <Typography className="sold-out">CLOSED</Typography>
              )}
              <div className="info-btn-wrapper">
                <Button
                  variant="contained"
                  className="info-btn"
                  onClick={() => navigate(`/market-history/${grand_id}/`)}
                >
                  More Info
                </Button>
              </div>
            </div>

            {nft.detail?.amount > 0 &&
              componentState === "active" && (
                <div>
                  <div className="d-flex justify-content-between align-items-top">
                    <div>
                      <FormGroup>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            marginTop: "5px",
                            fontWeight: 500,
                          }}
                        >
                          Carbon Credits
                        </Typography>
                        <Input
                          name="unit"
                          type="number"
                          required
                          min={1}
                          max={nft?.detail?.amount}
                          value={formData.unit}
                          placeholder="# units"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              unit: e.target.value,
                              total_price: e.target.value * nft?.detail?.price,
                            });
                          }}
                        />
                        <small style={{ fontSize: "12px" }}>
                          Number of units you want to buy
                        </small>
                      </FormGroup>
                    </div>

                    <div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          marginTop: "5px",
                          fontWeight: 500,
                        }}
                      >
                        Price
                      </Typography>
                      <span
                        sx={{
                          color: "success",
                          width: "100%",
                          marginTop: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <img
                          className="m-1"
                          src={algo}
                          alt="thumb"
                          width={25}
                        />
                        {(formData.unit * nft?.detail?.price) / 1000000}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className={`buy-btn ${
                        buyClicked === true ? "active-buy-btn" : ""
                      }`}
                      onClick={() => buyFromPrimary(nft)}
                      color="success"
                      variant="contained"
                    >
                      Buy
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </Paper>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default PrimaryItem;
