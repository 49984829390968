import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, capitalize } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { API_URL, CONFIG } from '../utils';
import { useSelector } from 'react-redux';
import algo from "../assets/algo.png";

const Dashboard = () => {

    // const [url, setUrl] = useState(`https://testnet.algoexplorer.io/tx/`)
    const [org_wallets, set_org_wallets] = useState([])
    const { org_id, org_name } = useSelector(state => state.auth)
    const [organisations, setOrganisations] = useState([])
    const [nfts, setNfts] = useState([])
    const [projects, setProjects] = useState({})
    const [carbonOffsets, setCarbonOffsets] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalTonnes, setTotalTonnes] = useState(0)
    const [totalCnetFees, setTotalCnetFees] = useState(0)
    const [my_wallet_address, set_my_wallet_address] = useState([])
    const [nft_create_history, set_nft_create_history] = useState([])
    const [nft_buy_history_data, set_nft_buy_history_data] = useState([])
    const [nft_sell_history_data, set_nft_sell_history_data] = useState([])

    const user_id = localStorage.getItem('user_id');
    let navigate = useNavigate();
    const handleClickNFTDetails = (nft_id) =>{
        navigate(`/market-history/${nft_id}`); 
    }
    const getOrgs = () => {
        axios.get(`${API_URL}/organisations/dashboard/`, CONFIG)
            .then(res => {
                console.log(res.data)
                setOrganisations(res.data)
            })
    }

    const get_dashboard_data = async () => {

        try {
            const { data } = await axios.get(`${API_URL}/organisations/dashboard/data/${org_id}/`, CONFIG)
            console.log({data})
            set_nft_create_history(data.nft_create_history)
            set_nft_buy_history_data(data.nft_buy_history_data)
            set_nft_sell_history_data(data.nft_sell_history_data)
            set_my_wallet_address(data.my_wallet_address)
            if (data.org_wallets && data.org_wallets.length > 0) {

                let temp = data.org_wallets.filter(d => d.org_name !== org_name)

                temp = temp.reduce((acc, current) => {
                    if (!acc.some(item => item.org_name === current.org_name)) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
                set_org_wallets(temp)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        //getCarbonOffsets();
        if (org_id) {
            get_dashboard_data()
        }

        //getOrgs();
    }, [org_id])


    const calculate_carbon_credits = (info) => {

        if (info.main_carbon_credits) {
            return (info.main_carbon_credits / info.main_total) * info.unit
        } else {
            return (info.carbon_credits / info.total) * info.unit
        }
    }

    const approveOrganisation = id => {
        axios.get(`${API_URL}/organisations/approve/${id}/`, CONFIG)
            .then(res => {
                if (res.data.status === 'success') {
                    swal({
                        title: "Organisattion Approve",
                        text: "Organisation Approved Successfully",
                        icon: "success",
                        button: false,
                        timer: 1300,
                    });
                    getOrgs();
                }
            })
    }

    const getCarbonOffsets = () => {
        axios.get(`${API_URL}/organisations/${user_id}/dashboard/`, CONFIG)
            .then(res => {
                console.log('dashboard response:', res.data);
                console.log('nft data: ', res.data.nfts)
                setCarbonOffsets(res.data.data)
                setTotalAmount(res.data.total_amount);
                setTotalTonnes(res.data.total_tonnes);
                setTotalCnetFees(res.data.cnet_fees);
                setNfts(res.data.nfts)
                setProjects(res.data.projects)
            })
    }
    // console.log(my_wallet_address)
    // console.log(org_wallets)

    return (
        <div className='bg py-4'>
            <div className='container px-4 px-lg-0 px-md-0 px-sm-0' >
                {/* <div className="row px-2">
                    {
                        localStorage.getItem('role') === 'Admin' ? (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Organisation name</TableCell>
                                            <TableCell align="right">Legal status</TableCell>
                                            <TableCell align="right">Country</TableCell>
                                            <TableCell align="right">Email</TableCell>
                                            <TableCell align="right">Linked in</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {organisations.map((row) => (
                                            <TableRow
                                                key={row.organisation_name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.organisation_name}
                                                </TableCell>
                                                <TableCell align="right">{row.legal_status}</TableCell>
                                                <TableCell align="right">{row.organisation_country}</TableCell>
                                                <TableCell align="right">{row.email}</TableCell>
                                                <TableCell align="right">{row.linked_in}</TableCell>
                                                <TableCell align="right">
                                                    <Button variant="outlined" color="success" size="small" onClick={() => approveOrganisation(row.id)}>Approve</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : ('')
                    }
                </div> */}

                <div className="row mb-2 mt-4">

                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md-4 mt-sm-4 mt-xs-4 mt-4 mt-lg-0">
                        <h2 className='mb-3'>Fee Table</h2>
                        <TableContainer component={Paper} style={{ height: '500px', overflowY: 'scroll' }}>
                            <Table sx={{ minWidth: 650, minheight: 200 }} aria-label="simple table">
                                <TableHead sx={{ color: 'white', backgroundColor: '#7B3F00' }}>
                                    <TableRow>
                                        {/* <TableCell sx={{ color: 'white' }}>Certificate owner</TableCell> */}
                                        <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>No </TableCell>
                                        <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>Organisations</TableCell>
                                        <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>⁠DCarbonX ID</TableCell>
                                        <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>Bought/ Sold Unit</TableCell>
                                        {/* <TableCell align="center" sx={{ color: 'white' }}>Wallet Address</TableCell> */}
                                        <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>Price</TableCell>
                                        <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>Fee Type</TableCell>
                                        <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>Issued/ Bought/ Sold CO<sub><b>2</b></sub> Credits</TableCell>
                                        <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>DCarbonX Fee</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        nft_create_history.map((l, i) => <TableRow key={i} className='text-success' >
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{i + 1}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00', textWrap: 'nowrap' }}><strong>{l.org}</strong></TableCell>
                                            <TableCell align="center"
                                             onClick={() => handleClickNFTDetails(l.nft_id)}
                                             sx={{ color: '#7B3F00', 
                                                  cursor: 'pointer',
                                                  '&:hover': {
                                                    textDecoration: 'underline',
                                                  },
                                            }}
                                            
                                            ><strong>{l.nft_state_id}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00',
                                                  cursor: 'pointer',
                                                  '&:hover': {
                                                    textDecoration: 'underline',
                                                  }
                                            }}><strong></strong></TableCell>
                                            {/* <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                {
                                                    l.wallet_addresses.map(wallet => <div key={wallet}><strong>{wallet}</strong> <br /></div>)
                                                }
                                            </TableCell> */}
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong></strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>NFT Issued</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <strong>{l.carbon_credits}</strong>
                                                    <span>{l.unit_name}</span>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.fee.toFixed(2)}</strong>
                                                </div>
                                            </TableCell>
                                        </TableRow>)
                                    }

                                    {
                                        nft_buy_history_data.map((l, i) => <TableRow key={i} className='text-success' >
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{nft_create_history.length + i + 1}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.org}</strong></TableCell>
                                            <TableCell align="center"
                                            onClick={() => handleClickNFTDetails(l.nft_id)}
                                             sx={{ color: '#7B3F00',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                  textDecoration: 'underline',
                                                }
                                        }}><strong>{l.nft_state_id}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.unit}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.price / 1000000}</strong>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>NFT Buy</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <strong>{calculate_carbon_credits(l)}</strong>
                                                    <span>{l.unit_name}</span>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.fee.toFixed(2)}</strong>
                                                </div>
                                            </TableCell>
                                        </TableRow>)
                                    }

                                    {
                                        nft_sell_history_data.map((l, i) => <TableRow key={i} className='text-success' >
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{nft_create_history.length + nft_buy_history_data.length + i + 1}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.org}</strong></TableCell>
                                            <TableCell align="center" 
                                            onClick={() => handleClickNFTDetails(l.nft_id)}
                                            sx={{ color: '#7B3F00',
                                               cursor: 'pointer',
                                               '&:hover': {
                                                 textDecoration: 'underline',
                                               }
                                            }}><strong>{l.nft_state_id}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.unit}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.price / 1000000}</strong>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>NFT Sell</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <strong>{calculate_carbon_credits(l)}</strong>
                                                    <span>{l.unit_name}</span>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.fee.toFixed(2)}</strong>
                                                </div>
                                            </TableCell>
                                        </TableRow>)
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

                <div className="row mb-2 mt-4">

                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md-4 mt-sm-4 mt-xs-4 mt-4 mt-lg-0">
                        <h2 className='mb-3 h4'>Wallet Addresses</h2>
                        <div>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <h6 style={{ color: '#7B3F00', textTransform: 'capitalize' }}>{org_name}</h6>
                                </AccordionSummary>
                                <TableContainer component={Paper} style={{ height: 'auto', overflowY: 'scroll', padding: '10px' }}>
                                    <Table sx={{ minWidth: 650, minHeightheight: 200 }} aria-label="simple table">
                                        <TableHead sx={{ color: 'white', backgroundColor: '#7B3F00' }}>
                                            <TableRow>
                                                <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>Algorand Blockchain Wallet Address</TableCell>
                                                <TableCell align="center" sx={{ color: 'white' }}>Wallet Address</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                my_wallet_address && my_wallet_address.length > 0 && my_wallet_address.map((a, i) => <TableRow key={i} className='text-success' >
                                                    <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{i + 1}</strong></TableCell>

                                                    <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                        <strong>{a}</strong>
                                                    </TableCell>

                                                </TableRow>)
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Accordion>
                            {

                                org_wallets && org_wallets.length > 0 && org_wallets.map((w, i) => <Accordion key={i}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <h6 style={{ color: '#7B3F00', textTransform: 'capitalize' }}>{w.org_name}</h6>
                                    </AccordionSummary>
                                    <TableContainer component={Paper} style={{ height: 'auto', overflowY: 'scroll', padding: '10px' }}>
                                        <Table sx={{ minWidth: 650, minHeightheight: 200 }} aria-label="simple table">
                                            <TableHead sx={{ color: 'white', backgroundColor: '#7B3F00' }}>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ color: 'white', textWrap: 'nowrap' }}>Algorand Blockchain Wallet Address</TableCell>
                                                    <TableCell align="center" sx={{ color: 'white' }}>Wallet Address</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {
                                                    w.wallet_addresses && w.wallet_addresses.length > 0 && w.wallet_addresses.map((a, i) => <TableRow key={i} className='text-success' >
                                                        <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{i + 1}</strong></TableCell>

                                                        <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                            <strong>{a}</strong>
                                                        </TableCell>

                                                    </TableRow>)
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Accordion>)

                            }

                        </div>

                        {/* <TableContainer component={Paper} style={{ height: '500px', overflowY: 'scroll' }}>
                            <Table sx={{ minWidth: 650, minHeightheight: 200 }} aria-label="simple table">
                                <TableHead sx={{ color: 'white', backgroundColor: '#7B3F00' }}>
                                    <TableRow>
                                        <TableCell align="center" sx={{ color: 'white' }}>Algorand Blockchain Wallet Address</TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>Wallet Address</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        my_wallet_address && my_wallet_address.length > 0 && my_wallet_address.map((a, i) => <TableRow key={i} className='text-success' >
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{i + 1}</strong></TableCell>

                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <strong>{a}</strong>
                                            </TableCell>

                                        </TableRow>)
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
