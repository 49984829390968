import React from 'react';
import Chart from 'react-apexcharts';



const formateLabel = (d, total) => {
    let value = (100 * d.value) / total
    value = value.toFixed(1)
    return `${d.id} - ${value}%`
}
//@ts-ignore
const PieChart = ({ data }) => {

    let labels = []
    let series = []
    let total = data.reduce((accumulator, current) => accumulator + current.value, 0);

    for (let i = 0; i < data.length; i++) {
        let result = formateLabel(data[i], total)
        labels.push(result)
        series.push(data[i].value)
    }

    var options = {
        chart: {
            width: 350,
            height: 250,
            type: "pie",
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
        tooltip: {
            y: {
                //@ts-ignore
                formatter: function (value) {
                    return value;
                },
                title: {
                    //@ts-ignore
                    formatter: function (seriesName) {
                        let a = seriesName?.split(' - ')[0] || seriesName
                        return a + ":";
                    },
                },
            },
        },
        stroke: {
            colors: ["00FFFFFF"],
        },
        labels: labels,
        colors: ['#5CEDBC', '#FECC6C', '#FF8899', '#5CB8FD'],
        dataLabels: {
            enabled: false,
        },
        markers: {
            colors: ["#F44336"],
        },
        legend: {
            show: !0,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            floating: !1,
            fontSize: "14px",
            textShadow: "none !importent",
        },
        responsive: [
            {
                breakpoint: 350,
                options: {
                    chart: {
                        width: 300,
                    },
                },
            },

        ],
    };

    return (
        <div className="flex items-center">
            <Chart
                id="pie_chart"
                options={options}
                series={series}
                type="pie"
                width={350}
                height={250}
            />
        </div>
    );
}

export default PieChart;